module.exports = [{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Material Symbols Outlined","file":"https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"},{"name":"Noto Sans JP","file":"https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"duration":500},
    },{
      plugin: require('../node_modules/@imgix/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"defaultImgixParams":{"auto":["compress","format"]},"fields":[{"nodeType":"MicrocmsContentTopImage","fieldName":"imgixImage","rawURLKey":"url"},{"nodeType":"MicrocmsActivitiesImage","fieldName":"imgixImage","rawURLKey":"url"},{"nodeType":"MicrocmsContent","fieldName":"decorativeImgixImage1","rawURLKey":"decorativeImage1.url"},{"nodeType":"MicrocmsContent","fieldName":"decorativeImgixImage2","rawURLKey":"decorativeImage2.url"},{"nodeType":"MicrocmsContent","fieldName":"decorativeImgixImage3","rawURLKey":"decorativeImage3.url"},{"nodeType":"MicrocmsContent","fieldName":"decorativeImgixImage4","rawURLKey":"decorativeImage4.url"},{"nodeType":"MicrocmsContent","fieldName":"decorativeImgixImage5","rawURLKey":"decorativeImage5.url"},{"nodeType":"MicrocmsContent","fieldName":"decorativeImgixImage6","rawURLKey":"decorativeImage6.url"}]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
